import {Injectable} from '@angular/core';
import {LoggerControllerService} from '@matchsource/api-generated/common';
import {AnalyticsModel} from '@matchsource/models/analytics';
import {toDto} from './utils';
import flow from 'lodash-es/flow';
import {firstValueFrom} from 'rxjs';
import {skipError, skipSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsApiService {
  constructor(private readonly logger: LoggerControllerService) {}

  async log(data: AnalyticsModel): Promise<void> {
    await firstValueFrom(
      this.logger.log(
        {body: toDto(data)},
        {
          context: flow(skipSpinner, skipError)(),
        }
      )
    );
  }
}
