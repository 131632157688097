import {MapTo} from '@matchsource/api-utils';
import {AnalyticsModel} from '@matchsource/models/analytics';
import {UserAnalytics} from '@matchsource/api-generated/common';

export const toDto: MapTo<AnalyticsModel, UserAnalytics> = (model: AnalyticsModel): UserAnalytics => ({
  session_guid: model.sessionGuid,
  event_ts: model.eventTimestamp,
  ...('searchGuid' in model ? {search_guid: model.searchGuid} : {}),
  Array: model.items.map(item => ({
    event_category: item.eventCategory,
    event_class: item.eventClass,
    event_action: item.eventAction,
    ...('eventOption' in item ? {event_option: item.eventOption} : {}),
  })),
});
