import {ActivityType, SystemActivityType} from '@matchsource/models/activity-dates';

export enum EventAction {
  Enable = 'Enable',

  Disable = 'Disable',

  Response = 'Response',

  NumFetches = 'NumFetches',

  Login = 'Login',

  Logout = 'Logout',
  Expanded = 'Expanded',
}

export enum EventCategory {
  Export = 'Export',
  Feature = 'Feature',
  Filter = 'Filter',
  Lookup = 'Lookup',
  Scroll = 'Scroll',
  Sort = 'Sort',
  Accordion = 'Accordion',
}

export interface AnalyticItemModel<TCategory extends string, TClass extends string, TOption, TAction = EventAction> {
  eventCategory: TCategory;
  eventClass: TClass;
  eventAction: TAction;
  eventOption?: TOption[];
}

export type AnalyticItemCommonModel = AnalyticItemModel<string, string, unknown, string>;

export enum LookupEventClasses {
  Patient = 'PatientLookup',
  Donor = 'DonorLookup',
  Cord = 'CordLookup',
  CryoPreserved = 'CryoPreservedLookup',
  Navigate = 'LookupNavigate',
}

export interface AnalyticsCommonModel {
  applicationName?: string;
  sessionGuid: string;
  searchGuid?: string;
  eventTimestamp: string;
}

export type AnalyticsModel = AnalyticsCommonModel & {
  items: AnalyticItemCommonModel[];
};

export type SearchResultsScrollAnalyticModel = AnalyticItemModel<
  EventCategory.Scroll,
  'SearchResultsScroll',
  number,
  EventAction.NumFetches
>;

export type SearchResultsExportAnalyticModel = AnalyticItemModel<
  EventCategory.Export,
  'SearchResultsExport',
  'CSV' | 'PDF',
  EventAction.Enable
>;

export type SearchResultsAlleleRevealAnalyticModel = Omit<
  AnalyticItemModel<EventCategory.Feature, 'AlleleReveal', unknown, EventAction.Enable | EventAction.Disable>,
  'eventOption'
>;

export type LoginLogoutAnalyticsModel = AnalyticItemModel<
  EventCategory.Feature,
  'UserSession',
  unknown,
  EventAction.Login | EventAction.Logout
>;

export type FilterAnalyticsModel = AnalyticItemModel<
  EventCategory.Filter,
  'ClearAllFilters' | 'DonorDomesticFilter' | 'SourceToggle',
  unknown,
  EventAction.Enable | EventAction.Disable
>;

export type ImportantDatesAnalyticsModel = AnalyticItemModel<
  EventCategory.Filter,
  'ImportantDates-Date' | 'ImportantDates-PatientRID' | 'ImportantDates-CaseActivity',
  unknown,
  EventAction.Enable | EventAction.Disable
>;

export type ImportantDatesAccordionAnalyticsModel = AnalyticItemModel<
  EventCategory.Accordion,
  'ImportantDates-CaseActivity',
  unknown,
  EventAction.Expanded
>;

export type SortAnalyticsModel = AnalyticItemModel<EventCategory.Sort, string, string, EventAction.Enable>;

export type LookupAnalyticModel = AnalyticItemModel<
  EventCategory.Lookup,
  LookupEventClasses,
  unknown,
  EventAction.Enable
>;

export type AnalyticsCaseActivity =
  | 'INFO_SESSION'
  | 'PHYSICAL_EXAM'
  | 'PRE_COLLECTION'
  | 'EXPECTED_CLEARANCE'
  | 'FILGRASTIM'
  | 'CONF_COLLECTION'
  | 'PREP_START'
  | 'INFUSION'
  | 'SHIPPED'
  | 'SYSTEM_NOTIFICATIONS'
  | 'ALL';

const ANALYTICS_CASE_ACTIVITY_MAP: Record<ActivityType | SystemActivityType, AnalyticsCaseActivity> = {
  [ActivityType.Shipped]: 'SHIPPED',
  [ActivityType.Infusions]: 'INFUSION',
  [ActivityType.PrepStart]: 'PREP_START',
  [ActivityType.ConfirmedCollection]: 'CONF_COLLECTION',
  [ActivityType.FligrastimStart]: 'FILGRASTIM',
  [ActivityType.ExpectedClearance]: 'EXPECTED_CLEARANCE',
  [ActivityType.PreCollection]: 'PRE_COLLECTION',
  [ActivityType.PhysicalExam]: 'PHYSICAL_EXAM',
  [ActivityType.InformationSession]: 'INFO_SESSION',
  [SystemActivityType.SystemMaintenance]: 'SYSTEM_NOTIFICATIONS',
  [SystemActivityType.AllCaseActivities]: 'ALL',
};

export const mapActivityTypeToAnalytics = (
  activityType: ActivityType | SystemActivityType | null
): AnalyticsCaseActivity => {
  if (!activityType) {
    return 'ALL';
  }

  return ANALYTICS_CASE_ACTIVITY_MAP[activityType];
};
