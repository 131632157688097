import {SourceType} from '@matchsource/models/source';

export enum ActivityType {
  InformationSession = 'Information_Session',
  PhysicalExam = 'PhysicalExam',
  PreCollection = 'PreCollection',
  ExpectedClearance = 'ExpectedClearance',
  FligrastimStart = 'FligrastimStart',
  ConfirmedCollection = 'ConfirmedCollection',
  PrepStart = 'PrepStart',
  Infusions = 'Infusions',
  Shipped = 'Shipped',
}

export enum SystemActivityType {
  AllCaseActivities = 'AllCases',
  SystemMaintenance = 'SystemMaintenance',
}

export type ActivityDetailsKey = {sourceId: MsApp.Guid; sourceType: SourceType};

export interface ActivityDateModel {
  activityDate: MsApp.DateString;
  activityType: ActivityType;
  orderId: MsApp.Guid;
  patientId: MsApp.Guid;
  patientFirstName: string;
  patientLastName: string;
  patientMiddleName: string;
  sourceType: SourceType;
  sourceId: MsApp.Guid;
  rid: number;
  coordinatorId: number;
}

export interface DonorActivityDetailsModel {
  sourceId: MsApp.Guid;
  displayId: string;
}

export interface CordActivityDetailsModel {
  sourceId: MsApp.Guid;
  displayId: string;
}

export type ActivityDetailsModel = DonorActivityDetailsModel | CordActivityDetailsModel;

export type ActivityDateWithDetailsModel = ActivityDateModel & {
  details: ActivityDetailsModel;
};

export const ALL_ACTIVITIES = {
  code: SystemActivityType.AllCaseActivities,
  description: 'DASHBOARD_CALENDAR.ALL_CASES',
};

export const CASE_ACTIVITIES = [
  {code: ActivityType.InformationSession, description: 'DASHBOARD_CALENDAR.ACTIVITIES.INFORMATION_SESSION'},
  {code: ActivityType.PhysicalExam, description: 'DASHBOARD_CALENDAR.ACTIVITIES.PHYSICALEXAM'},
  {code: ActivityType.PreCollection, description: 'DASHBOARD_CALENDAR.ACTIVITIES.PRECOLLECTION'},
  {code: ActivityType.ExpectedClearance, description: 'DASHBOARD_CALENDAR.ACTIVITIES.EXPECTEDCLEARANCE'},
  {code: ActivityType.FligrastimStart, description: 'DASHBOARD_CALENDAR.ACTIVITIES.FLIGRASTIMSTART'},
  {code: ActivityType.ConfirmedCollection, description: 'DASHBOARD_CALENDAR.ACTIVITIES.CONFIRMEDCOLLECTION'},
  {code: ActivityType.PrepStart, description: 'DASHBOARD_CALENDAR.ACTIVITIES.PREPSTART'},
  {code: ActivityType.Infusions, description: 'DASHBOARD_CALENDAR.ACTIVITIES.INFUSIONS'},
  {code: ActivityType.Shipped, description: 'DASHBOARD_CALENDAR.ACTIVITIES.SHIPPED'},
  {code: SystemActivityType.SystemMaintenance, description: 'DASHBOARD_CALENDAR.SYSTEM_MAINTENANCE'},
];

export interface CaseActivitiesModel {
  code: string;
  description: string;
}
